export const MenuItems = [
  {
    title: "Calendar",
    path: "/calendar",
    cName: "nav-links",
  },
  // {
  //   title: 'Vote',
  //   path: '/elections',
  //   cName: 'nav-links-caret',
  // },
  {
    title: "News",
    path: "/news",
    cName: "nav-links",
  },
  {
    title: "Contact",
    path: "/contact",
    cName: "nav-links",
  },
];
