import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";

const styles = ({ palette }) => ({
  textCenter: {
    textAlign: "center",
  },
  content: {
    fontFamily: "open_sansbold",
  },
});

const Content = withStyles(styles, { name: "Content" })(
  ({ children, appointmentData, classes, ...restProps }) => (
    <AppointmentTooltip.Content
      style={{ fontFamily: "open_sansbold" }}
      {...restProps}
      appointmentData={appointmentData}
    >
      <Grid container alignItems="center" className={classes.content}>
        <Grid item xs={2} className={classes.textCenter}></Grid>
        <Grid item xs={10}>
          <span>Location: {appointmentData.location}</span>
        </Grid>
        <Grid item xs={2} className={classes.textCenter}></Grid>
        <Grid item xs={10}>
          <span>
            <Link
              href={appointmentData.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click To Sign Up
            </Link>
          </span>
        </Grid>
      </Grid>
    </AppointmentTooltip.Content>
  )
);

export default Content;
