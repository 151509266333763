import React from "react";
import dragcon from "../photos/swingleft_dragcon.jpg";
import groupPhoto from "../photos/swingleft_groupphoto.jpg";
import HomeModal from "./HomeModal";

export const Home = () => {
  return (
    <>
      <div className="homepage">
        <div id="home">
          <p className="home-text">
            Make a real impact on the elections that determine the balance of
            power in our country.
          </p>
          {/* <div>
        <img
          src={dragcon}
          id='dragcon'
          alt='SwingLeft volunteers pose with drag queen and New York City Council candidate, Marti Allen-Cummings'
        />
      </div>
      <div>
        <img
          src={groupPhoto}
          id='groupPhoto'
          alt='A group of smiling volunteers pose together on a stoop with a large SwingLeft banner'
        />
      </div> */}
        </div>
      </div>
    </>
  );
};
