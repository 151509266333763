import React, { useEffect, useState } from "react";

import { getBlogPosts } from "../api/contentful";

export const useGetBlogPosts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPostsAsync = async () => {
      const fetchedPosts = await getBlogPosts();
      setPosts(fetchedPosts);
    };
    getPostsAsync();
  }, []);

  return { posts };
};
