import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";

const Appointment = ({ children, style, ...restProps }) => (
  <Appointments.Appointment
    {...restProps}
    style={{
      ...style,
      backgroundColor: "#00c4ff",
      borderRadius: "8px",
      height: "20px",
    }}
  >
    {children}
  </Appointments.Appointment>
);

export default Appointment;
