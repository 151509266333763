import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { VoteItems } from './VoteItems';

export function Dropdown(handleClick) {
  const [touch, setTouch] = useState(false);

  const handleTouch = () => {
    setTouch(!touch);
  };

  return (
    <>
      <ul
        onClick={(handleTouch, handleClick)}
        className={touch ? 'dropdown clicked' : 'dropdown'}
      >
        {VoteItems.map((item, index) => {
          return (
            <li key={item.index}>
              <Link
                className='dropdown-link'
                to={item.path}
                onClick={() => setTouch(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}
