export const VoteItems = [
  {
    title: 'Upcoming NYC Elections',
    path: '/elections',
  },
  {
    title: 'What is Ranked Choice Voting?',
    path: '/rankedvoting',
  },
  {
    title: 'Find Your Polling Place',
    path: '/pollingplace',
  },
  {
    title: 'Check Registration Status',
    path: '/registration',
  },
  {
    title: 'Find Your Representatives',
    path: '/representatives',
  },
];
