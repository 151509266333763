import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({


  button: {
    size: 40,
    fontWeight: "bold",
    margin: 3,
    backgroundColor: "#00c4ff",
    fontFamily: "open_sansbold"
  },
}));

const RepForm = () => {
  const classes = useStyles();

  const [state, setState] = useState({
    street1: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
  });


  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // await clearRepInfo();
    const address = `${state.street1} ${state.street2} ${state.city} ${state} ${state.zip}`;
    // await fetchRepInfo(address);
    setState({ street1: "", street2: "", city: "", state: "", zip: "" });
  };

  return (
    <div>
      <div id="form-container">
        <hr />
        <form id="voter-form" onSubmit={handleSubmit}>
          <h2>Please enter your address to look up your representatives.</h2>
          <div className="break"></div>
          <TextField
            className="input"
            label="Street 1"
            name="street1"
            type="text"
            onChange={handleChange}
            value={state.street1}
            variant="outlined"
          />

          <TextField
            className="input"
            label="Street 2"
            name="street2"
            type="text"
            onChange={handleChange}
            value={state.street2}
            variant="outlined"
          />
          <TextField
            className="input"
            label="City"
            name="city"
            type="text"
            onChange={handleChange}
            value={state.city}
            variant="outlined"
          />
          <TextField
            className="input"
            label="State"
            name="state"
            type="text"
            onChange={handleChange}
            value={state.state}
            variant="outlined"
          />
          <TextField
            className="input"
            label="Zip"
            name="zip"
            type="text"
            onChange={handleChange}
            value={state.zip}
            variant="outlined"
          />
          <div className="break"></div>
          <Button
           className={classes.button}
            variant="contained"
            type="submit"
            color="primary"
            size="large"
          >
            Search
          </Button>
        </form>
      </div>
    </div>
  );
};

export default RepForm;
