import { ContentfulPost } from "./ContentfulPost";
import React from "react";
import { useGetBlogPosts } from "../../hooks/useGetBlogPosts";

export const News = () => {
  const { posts } = useGetBlogPosts();

  return (
    <div className="news-container">
      <div>
        {posts.map((post) => {
          return <ContentfulPost key={post?.fields.title} post={post} />;
        })}
      </div>
    </div>
  );
};
