import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Typography from "@mui/material/Typography";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export const ContentfulPost = ({ post }) => {
  const getHTMLforBody = (body) => {
    const htmlString = documentToHtmlString(body);
    return ReactHtmlParser(htmlString);
  };

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <div className="post-container">
      <Card sx={{ width: "100%" }}>
        <CardContent>
          <Typography
            sx={{
              fontFamily: "barlowcondensed_semibold",
              color: "#0a2852",
            }}
            gutterBottom
            variant="h3"
            component="div"
          >
            {post?.fields.title}
          </Typography>
          <Typography
            sx={{ fontFamily: "opensans_bold", color: "#0a2852" }}
            gutterBottom
            variant="subtitle1"
            component="div"
          >
            Published on{" "}
            {new Date(post?.fields.date).toLocaleDateString("en-US", options)}
          </Typography>
          {post?.fields?.image && (
            <CardMedia
              component="img"
              src={post?.fields.image.fields.file.url}
              sx={{ width: "60%", padding: 5, margin: "auto" }}
            />
          )}
          <Typography
            sx={{
              color: "#0a2852",
              whiteSpace: "pre-wrap",
            }}
            display="inline"
          >
            {getHTMLforBody(post?.fields.body)}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};
